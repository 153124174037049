export const MenuItems = [
    
    {
        title: 'ABOUT',
        url: 'about',
        cName: 'nav-links'

    },
    {
        title: 'PROJECTS',
        url: 'projects',
        cName: 'nav-links'

    },
    {
        title: 'CONTACT',
        url: 'contact',
        cName: 'nav-links'

    },
    // {
    //     title: 'RESUME',
    //     url: 'static/resume-2bbbf84d55b52395231f164494200798.pdf',
    //     cName: 'resumeBtnMobile cta-btn cta-btn--hero'
    // },
]
    
